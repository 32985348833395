.testimony h1 {
  color: var(--font-blk);
  text-align: center;
  padding-top: 60px;
  font-size: 2.5em;
  font-weight: 600;
}

.slider {
  max-width: 100rem;
  height: 60rem;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
}

.slide {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60rem;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.slide > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slider__btn {
  z-index: 1;
  color: #333;
  cursor: pointer;
  background: #ffffffb3;
  border: none;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  font-family: inherit;
  font-size: 3.25rem;
  position: absolute;
  top: 50%;
}

.slider__btn--left {
  font-size: 2em;
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  font-size: 2em;
  right: 6%;
  transform: translate(50%, -50%);
}

.dots {
  display: flex;
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
}

.dots__dot {
  opacity: .7;
  cursor: pointer;
  background-color: #b9b9b9;
  border: none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-right: 1.75rem;
  transition: all .5s;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  opacity: 1;
  background-color: #888;
}

.testimonial {
  z-index: auto;
  flex-direction: column;
  align-items: center;
  width: 85%;
  display: flex;
  position: relative;
}

.testimonial__text {
  margin-bottom: 2rem;
  font-size: .8rem;
}

.testimonial__header {
  text-align: left;
  margin-bottom: 1.7rem;
  font-size: 1.7rem;
  font-weight: 500;
}

.testimonial:before {
  content: "“";
  color: var(--color-primary);
  z-index: -1;
  font-family: inherit;
  font-size: 10rem;
  line-height: 1;
  position: absolute;
  top: -2.5rem;
  left: -1.2rem;
}

.testimonial__photo {
  border-radius: 5px;
  width: 200px;
}

.testimonial__name {
  text-align: right;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
}

.testimonial__author {
  justify-content: flex-end;
  margin-left: 3rem;
  font-style: normal;
  display: flex;
}

.testimonial__position {
  text-align: right;
  font-size: 1rem;
}

.section__title--testimonials {
  margin-bottom: 4rem;
}

@media (width >= 720px) {
  .testimonial {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    width: 75%;
  }

  .testimonial:before {
    font-size: 13rem;
    top: -2.5rem;
    left: -4.8rem;
  }

  .testimonial__header {
    font-size: 1.5rem;
  }

  .testimonial__text {
    color: #666;
    font-size: .9rem;
  }

  .testimonial__photo {
    width: 175px;
  }

  .testimonial__name {
    margin: 0;
    font-size: 1.7rem;
    font-weight: 500;
  }

  .testimonial__author {
    justify-content: flex-end;
    margin-left: 3rem;
    font-style: normal;
    display: flex;
  }

  .testimonial__position {
    font-size: 1.5rem;
  }

  .section__title--testimonials {
    margin-bottom: 4rem;
  }
}

@media (width >= 1024px) {
  .testimony {
    height: 58em;
  }

  .testimonial {
    flex-direction: row;
    align-items: flex-start;
    gap: 25px;
    width: 70%;
  }

  .testimonial:before {
    font-size: 13rem;
    top: -2.5rem;
    left: -4.8rem;
  }

  .testimonial__header {
    padding-bottom: 3%;
    font-size: 1.8em;
  }

  .testimonial__text {
    color: #666;
    padding-bottom: 5%;
    font-size: 1em;
  }

  .testimonial__photo {
    width: 275px;
  }

  .testimonial__name {
    margin: 0;
    font-size: 2em;
    font-weight: 500;
  }

  .testimonial__author {
    justify-content: flex-end;
    margin-left: 3.5rem;
    font-style: normal;
    display: flex;
  }

  .testimonial__position {
    font-size: 1.5rem;
  }

  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  .slide {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40rem;
    transition: transform 1s;
    display: flex;
    position: absolute;
    top: 0;
  }

  .slider__btn {
    z-index: 1;
    color: #333;
    cursor: pointer;
    background: #ffffffb3;
    border: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    font-family: inherit;
    font-size: 3.5rem;
    position: absolute;
    top: 28%;
  }

  .slider__btn--left {
    font-size: 2.3em;
    left: 6%;
    transform: translate(-50%, -50%);
  }

  .slider__btn--right {
    font-size: 2.3em;
    right: 6%;
    transform: translate(50%, -50%);
  }

  .dots {
    display: flex;
    position: absolute;
    bottom: 32%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (width >= 1440px) {
  .testimony {
    height: 65em;
  }

  .testimonial {
    flex-direction: row;
    align-items: flex-start;
    gap: 35px;
    width: 65%;
  }

  .testimonial:before {
    font-size: 13.5rem;
    top: -3rem;
    left: -4.8rem;
  }

  .testimonial__header {
    padding-bottom: 5%;
    font-size: 2.1em;
  }

  .testimonial__text {
    color: #666;
    padding-bottom: 5%;
    font-size: 1.2em;
  }

  .testimonial__photo {
    width: 300px;
  }

  .testimonial__name {
    margin: 0;
    font-size: 2.2em;
    font-weight: 500;
  }

  .testimonial__author {
    justify-content: flex-end;
    margin-left: 4rem;
    font-style: normal;
    display: flex;
  }

  .testimonial__position {
    font-size: 1.7rem;
  }

  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  .dots {
    display: flex;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  .slide {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50rem;
    transition: transform 1s;
    display: flex;
    position: absolute;
    top: 0;
  }

  .slider__btn {
    z-index: 1;
    color: #333;
    cursor: pointer;
    background: #ffffffb3;
    border: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    font-family: inherit;
    font-size: 3.5rem;
    position: absolute;
    top: 28%;
  }

  .slider__btn--left {
    font-size: 2.3em;
    left: 6%;
    transform: translate(-50%, -50%);
  }

  .slider__btn--right {
    font-size: 2.3em;
    right: 6%;
    transform: translate(50%, -50%);
  }
}
/*# sourceMappingURL=index.a548d80f.css.map */
