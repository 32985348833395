/* * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  } */

  .testimony h1{
    /* min-height: 2rem; */
    padding-top: 60px;
    font-size: 2.5em;
    font-weight: 600;
    color: var(--font-blk);
    text-align: center;
}
  
  /* body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: #444;
    line-height: 1.9;
    background-color: #f3f3f3;
  } */
  /* SLIDER */
  .slider {
    max-width: 100rem;
    height: 60rem;
    margin: 10px auto;
    position: relative;
  
    /* IN THE END */    
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 60rem;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* THIS creates the animation! */
    transition: transform 1s;
  }
  
  .slide > img {
    /* Only for images that have different size than slide */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider__btn {
    position: absolute;
    top: 50%;
    z-index: 1;
  
    border: none;
    background: rgba(255, 255, 255, 0.7);
    font-family: inherit;
    color: #333;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    font-size: 3.25rem;
    cursor: pointer;
  }
  
  .slider__btn--left {
    left: 6%;
    transform: translate(-50%, -50%);
    font-size: 2em;
  }
  
  .slider__btn--right {
    right: 6%;
    transform: translate(50%, -50%);
    font-size: 2em;
  }
  
  .dots {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  
  .dots__dot {
    border: none;
    background-color: #b9b9b9;
    opacity: 0.7;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 1.75rem;
    cursor: pointer;
    transition: all 0.5s;
  
    /* Only necessary when overlying images */
    /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
  }
  
  .dots__dot:last-child {
    margin: 0;
  }
  
  .dots__dot--active {
    /* background-color: #fff; */
    background-color: #888;
    opacity: 1;
  }
  
  /* TESTIMONIALS */

  .testimonial {
    width: 85%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: auto;
  }
  .testimonial__text{
    font-size: .8rem;
    margin-bottom: 2rem;
  }
  .testimonial__header{
    text-align: left;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 1.7rem;
  }
  .testimonial::before {
    content: '\201C';
    position: absolute;
    top: -2.5rem;
    left: -1.2rem;
    line-height: 1;
    font-size: 10rem;
    font-family: inherit;
    color: var(--color-primary);
    z-index: -1;
  }
  .testimonial__photo {
    width: 200px;
    border-radius: 5px;
  }
  .testimonial__name {
    text-align: right;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }
  .testimonial__author {
    margin-left: 3rem;
    font-style: normal;
    justify-content: flex-end;
    display: flex;
  }
  
  .testimonial__position {
    font-size: 1rem;
    text-align: right;
  }
  
  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  @media (min-width:720px){
    .testimonial {
      width: 75%;
      flex-direction: row;
      gap: 20px;
      align-items: flex-start;
    }
    
    .testimonial::before {
      top: -2.5rem;
      left: -4.8rem;
      font-size: 13rem;
    }
    
    .testimonial__header {
      font-size: 1.5rem;
    }
    
    .testimonial__text {
      font-size: .9rem;
      color: #666;
    }
  
    .testimonial__photo {
      width: 175px;
    }
  
    .testimonial__name {
      font-size: 1.7rem;
      font-weight: 500;
      margin: 0;
    }
    
    .testimonial__author {
    margin-left: 3rem;
    font-style: normal;
    justify-content: flex-end;
    display: flex;
    }
  
    .testimonial__position {
    font-size: 1.5rem;
    }
  
    .section__title--testimonials {
    margin-bottom: 4rem;
    }
  }

  @media (min-width:1024px){
  .testimony{
    height: 58em;
  }
  .testimonial {
    width: 70%;
    flex-direction: row;
    gap: 25px;
    align-items: flex-start;
  }
    
  .testimonial::before {
    top: -2.5rem;
    left: -4.8rem;
    font-size: 13rem;
  }
    
  .testimonial__header {
    font-size: 1.8em;
    padding-bottom: 3%;
  }
    
  .testimonial__text {
    font-size: 1em;
    color: #666;
    padding-bottom: 5%;
  }
  
  .testimonial__photo {
    width: 275px;
  }
  
  .testimonial__name {
    font-size: 2em;
    font-weight: 500;
    margin: 0;
  }
    
  .testimonial__author {
    margin-left: 3.5rem;
    font-style: normal;
    justify-content: flex-end;
    display: flex;
  }
  
  .testimonial__position {
    font-size: 1.5rem;
  }
    
  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40rem;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* THIS creates the animation! */
    transition: transform 1s;
  }
  
  .slider__btn {
    position: absolute;
    top: 28%;
    z-index: 1;
  
    border: none;
    background: rgba(255, 255, 255, 0.7);
    font-family: inherit;
    color: #333;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    font-size: 3.5rem;
    cursor: pointer;
  }
  
  .slider__btn--left {
    left: 6%;
    transform: translate(-50%, -50%);
    font-size: 2.3em;
  }
  
  .slider__btn--right {
    right: 6%;
    transform: translate(50%, -50%);
    font-size: 2.3em;
  }

  .dots {
    position: absolute;
    bottom: 32%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
}

@media (min-width:1440px){
  .testimony{
    height: 65em;
  }
  .testimonial {
    width: 65%;
    flex-direction: row;
    gap: 35px;
    align-items: flex-start;
  }
  
  .testimonial::before {
    top: -3rem;
    left: -4.8rem;
    font-size: 13.5rem;
  }
 
  .testimonial__header {
    font-size: 2.1em;
    padding-bottom: 5%;
  }
  
  .testimonial__text {
    font-size: 1.2em;
    color: #666;
    padding-bottom: 5%;
  }

  .testimonial__photo {
    width: 300px;
  }

  .testimonial__name {
    font-size: 2.2em;
    font-weight: 500;
    margin: 0;
  }
  
  .testimonial__author {
    margin-left: 4rem;
    font-style: normal;
    justify-content: flex-end;
    display: flex;
  }

  .testimonial__position {
    font-size: 1.7rem;
  }

  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  .dots {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50rem;

    display: flex;
    align-items: center;
    justify-content: center;

    /* THIS creates the animation! */
    transition: transform 1s;
  }

  .slider__btn {
    position: absolute;
    top: 28%;
    z-index: 1;

    border: none;
    background: rgba(255, 255, 255, 0.7);
    font-family: inherit;
    color: #333;
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    font-size: 3.5rem;
    cursor: pointer;
  }

  .slider__btn--left {
    left: 6%;
    transform: translate(-50%, -50%);
    font-size: 2.3em;
  }

  .slider__btn--right {
    right: 6%;
    transform: translate(50%, -50%);
    font-size: 2.3em;
  }
}